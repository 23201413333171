<template>
  <div>
    <div class="layout">
      <div class="banner">
        <h2 class="h2">{{$t('Traveler')}}</h2>
      </div>
      <div class="traveler-list cl">
        <router-link class="traveler-item"
                     v-for="item of list"
                     :key="item.philosopherId"
                     :to="{name:'TravelerDetail',params:{id:item.philosopherId}}">
          <avatar class="cover" :url="item.photoUrl" :is-self="false"/>
          <div class="name">{{item.penName}}</div>
        </router-link>
      </div>
      <div class="txt-c" v-if="totalPages>page||loading">
        <a class="btn-more" :disabled="loading" href="javascript:;" @click="loadMore">{{loading?'loading……':$t('View more')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
  import {getPhilosopheList} from '../../api/travels';
  import Avatar from '../../components/Avatar';

  export default {
    name: 'TravelerList',
    data() {
      return {
        page: 1,
        list: [],
        totalPages: 0,
        loading: false
      };
    },
    components: {Avatar},
    created() {
      this.getList();
    },
    methods: {
      // 获取达人列表
      getList() {
        if (!this.loading) {
          const data = {page: this.page, perPage: 18};
          this.loading = true;
          getPhilosopheList(data).then(res => {
            const {value} = res;
            const list = value.list || [];
            this.list = data.page === 1 ? list : this.list.concat(list);
            this.totalPages = value.totalPages;
            this.loading = false;
          }).catch(() => {
          });
        }
      },
      loadMore() {
        this.page += 1;
        this.getList();
      }
    }
  };
</script>
<style scoped lang="less">
  .banner{
    position:relative;height:200px;margin-top:30px;text-align:center;background:url(../../assets/images/banner.jpg) no-repeat top center;
    &:after{position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(255, 111, 97, 0.7);content:'';}
    &:before{display:inline-block;height:100%;vertical-align:middle;content:'';}
    .h2{display:inline-block;position:relative;z-index:1;vertical-align:middle;text-align:center;text-shadow:3px 2px 0 #ff6f61;color:#fff;font-size:48px;font-weight:bold;}
  }
  .traveler-list{padding-bottom:60px;}
  .traveler-item{
    float:left;width:120px;height:160px;margin-top:60px;margin-right:48px;
    &:nth-child(6n){margin-right:0;}
    .cover{width:120px;height:120px;border:4px solid rgba(254, 112, 97, 0.4);}
    .name{margin-top:20px;overflow:hidden;line-height:20px;white-space:nowrap;text-overflow:ellipsis;text-align:center;}
  }
  .btn-more{display:inline-block;margin-bottom:30px;padding:9px 21px;color:#ff6f61;font-size:16px;border:1px solid #ff6f61;border-radius:4px;}
</style>
